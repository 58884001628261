export class RailyError {
  constructor(
    public code: number,
    public i18n: string,
    public httpStatus?: number,
    public url?: string | null,
    public message?: string,
    public error?: any
  ) {}

  toString() {
    return JSON.stringify(this);
  }

  static app(error?: any) {
    return new RailyError(1, 'ERROR.APP', undefined, undefined, error);
  }

  static unauthorized(url: string | null | undefined, error?: any) {
    return new RailyError(2, 'ERROR.UNAUTHORIZED', 401, url, undefined, error);
  }

  static forbidden(url: string | null | undefined, error?: any) {
    return new RailyError(3, 'ERROR.FORBIDDEN', 403, url, undefined, error);
  }

  static notFound(url: string | null | undefined, error?: any) {
    return new RailyError(4, 'ERROR.NOT_FOUND', 404, url, undefined, error);
  }
}
