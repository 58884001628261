import { Component, Input } from '@angular/core';

@Component({
  selector: 'raily-section-title',
  templateUrl: './section-title.component.html',
  styleUrl: './section-title.component.scss',
})
export class SectionTitleComponent {
  @Input() title!: string;
}
