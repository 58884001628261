const regex = /\d{2}\s\d{2}\s\d{4}\s\d{3}-\d/g

export const formatWagonIdentifier = (identifier: string): string | null => {
  if (identifier.match(regex)) {
    return identifier;
  }
  if (identifier.length !== 12) {
    return null;
  }
  const arr = identifier.split('');
  arr.splice(2, 0, ' ');
  arr.splice(5, 0, ' ');
  arr.splice(10, 0, ' ');
  arr.splice(14, 0, '-');

  return arr.join('');
}
