import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, switchMap, tap } from "rxjs";
import { AuthData, AuthDataProps, AuthUser } from "../model";
import jwtDecode from 'jwt-decode';
import { NgxPermissionsService } from "ngx-permissions";
import { HttpService } from '@raily/shared';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userSubject$ = new BehaviorSubject<AuthUser | null>(null);
  private authData?: AuthData;
  private sessionStorageKey = 'auth_data';

  user$: Observable<AuthUser | null> = this.userSubject$.asObservable();
  loggedIn$: Observable<boolean> = this.user$.pipe(map(user => !!user));

  constructor(
    private http: HttpService,
    private permissionsService: NgxPermissionsService,
  ) {
    this.tryAutoLogin();
  }

  login(login: string, password: string) {
    return this.http.post<string, { login: string, password: string }>(
      'auth/login',
      { login, password },
      { responseType: 'text' }
    ).pipe(
      map(token => this.decodeToken(token)),
      tap(data => this.setPermissions(data)),
      tap(data => this.setSession(data)),
      tap(data => this.setAuthData(data)),
      tap(data => this.setUser(data)),
      switchMap(() => this.user$),
    );
  }

  logout() {
    localStorage.removeItem(this.sessionStorageKey);
    this.userSubject$.next(null);
  }

  getToken() {
    this.verifyTokenExpiry(this.authData);
    return this.authData?.token;
  }

  forgotPassword(login: string) {
    return this.http.post('auth/reset-password', { login });
  }

  private tryAutoLogin() {
    const dataString = localStorage.getItem(this.sessionStorageKey);
    if (!dataString) {
      return;
    }
    const data = JSON.parse(dataString);
    this.verifyTokenExpiry(data);
    this.setPermissions(data);
    this.setAuthData(data);
    this.setUser(data);
  }

  private decodeToken(token: string): AuthData {
    const data = jwtDecode(token) as AuthData;
    data[AuthDataProps.Token] = token;
    return data;
  }

  private verifyTokenExpiry(data?: AuthData) {
    if (!data || data[AuthDataProps.Expiry] < Date.now() / 1000) {
      localStorage.removeItem(this.sessionStorageKey);
      this.logout();
      return;
    }
  }

  private setPermissions(data: AuthData): void {
    this.permissionsService.loadPermissions([
      ...data[AuthDataProps.Permissions],
      ...data[AuthDataProps.Features]
    ]);
  }

  private setSession(data: AuthData): void {
    localStorage.setItem(this.sessionStorageKey, JSON.stringify(data));
  }

  private setAuthData(data: AuthData) {
    this.authData = data;
  }

  private setUser(data: AuthData): void {
    const user: AuthUser = {
      email: data[AuthDataProps.Email],
      name: data[AuthDataProps.Name],
      surname: data[AuthDataProps.Surname],
      organizationId: data[AuthDataProps.OrganisationId],
      role: data[AuthDataProps.Roles]
    }
    this.userSubject$.next(user);
  }

  resetPassword(token: string, login: string, password: string) {
    return this.http.post('auth/password', { login: login, oldPassword: token, newPassword: password });
  }
}
