import { Component, Input } from '@angular/core';
import { AuditData } from '../../../model';

@Component({
  selector: 'raily-audit-info',
  templateUrl: './audit-info.component.html',
  styleUrls: ['./audit-info.component.scss'],
})
export class AuditInfoComponent {
  @Input() audit!: AuditData;
}
