export interface TranslatedProperty {
  translations: {
    code: string;
    value: string;
  }[];
}

export function initTranslatedProperty(): TranslatedProperty {
  return {
    translations: []
  }
}
