<raily-search-autocomplete
  [label]="label"
  [url]="'stations/search'"
  [apiSearch]="true"
  [withLocation]="true"
  [labelField]="'name'"
  [required]="true"
  [ngModel]="value"
  [disabled]="isDisabled"
  (ngModelChange)="onChange($event)">
</raily-search-autocomplete>
