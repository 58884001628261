<raily-page-layout [title]="'FEATURE.SETTINGS.NAME'">
  <ng-container content>

    <raily-center-card>
      <form class="raily-form">
        <raily-input
          name="language"
          type="select"
          [required]="true"
          [ngModel]="languageService.currentLanguage"
          (ngModelChange)="onLanguageChange($event)"
          [label]="'FEATURE.SETTINGS.FORM.LANGUAGE'"
          [options]="languageService.languageOptions"
          [optionValue]="'id'"
          [optionLabel]="'label'">
        </raily-input>
      </form>

    </raily-center-card>

  </ng-container>
</raily-page-layout>
