import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  cache!: { lat: number, long: number };

  async cacheLocation() {
    this.cache = await this.getCurrentLocation();
  }

  getLocation(): { lat: number, long: number } {
    return this.cache;
  }

  getCurrentLocation(): Promise<{ lat: number, long: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=> {
          const long = position.coords.longitude;
          const lat = position.coords.latitude;
          resolve({ lat, long });
        },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      } else {
        console.log("No support for geolocation");
        reject();
      }
    });
  }
}
