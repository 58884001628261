<div class="damage-report-details-images w-full flex flex-row justify-content-center">
  <p-galleria [value]="images()" [showItemNavigators]="true" [containerStyle]="{ 'max-width': layoutService.isMobile ? '90vw' : '60vw' }" [numVisible]="layoutService.isMobile ? 2 : 5">
    <ng-template pTemplate="item" let-item>
      <img [src]="item" [ngStyle]="{ 'max-width': layoutService.isMobile ? '90vw' : '60vw' }" />
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
      <div class="grid grid-nogutter justify-content-center">
        <img [src]="item" [ngStyle]="{ 'max-width': layoutService.isMobile ? '25vw' : '120px' }"/>
      </div>
    </ng-template>
  </p-galleria>
</div>
