import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'raily-station-autocomplete',
  templateUrl: './station-autocomplete.component.html',
  styleUrl: './station-autocomplete.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StationAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => StationAutocompleteComponent),
      multi: true
    }
  ]
})
export class StationAutocompleteComponent implements ControlValueAccessor, Validator {
  @Input() required = false;
  @Input() type: 'destination' | 'forwarding' = 'destination';

  value?: any;
  isDisabled = false;

  onChange = (_: any) => {};
  onTouch = () => {};
  onValidatorChange = () => {};

  get label(): string {
    return this.type === 'destination' ? 'SHARED.DESTINATION_STATION' : 'SHARED.FORWARDING_STATION';
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    if (this.required && (this.value == null)) {
      return { required: true };
    }
    return null;
  }
}
