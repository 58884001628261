import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'raily-list-action-buttons',
  templateUrl: './list-action-buttons.component.html',
  styleUrls: ['./list-action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListActionButtonsComponent {
  @Input() hide?: string[];
  @Output() details = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();

  showButton(button: string): boolean {
    return !this.hide?.includes(button);
  }
}
