import { NgModule } from '@angular/core';
import { CoreModule } from '@raily/core';
import { SharedModule } from '@raily/shared';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HttpClientModule } from '@angular/common/http';
import { Transloco } from './config/transloco';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { navigationProvider } from './config/navigation';
import { AuthModule } from '@raily/auth';
import { primeNGConfigProvider } from './config/primeng';
import { organizationSettingsProvider } from './config/organization';
import { environmentProvider } from './config/environment';
import { appRoutesProvider, routes } from './config/routes';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    AuthModule.forRoot(),
    SharedModule,
    NgxPermissionsModule.forRoot(),
    Transloco.forRoot(),
  ],
  providers: [
    environmentProvider,
    primeNGConfigProvider,
    navigationProvider,
    appRoutesProvider,
    organizationSettingsProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
