import { Injectable } from '@angular/core';
import { HttpService } from '@raily/shared';
import { Observable } from 'rxjs';
import { FileInfo } from '../../model/file-info.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpService: HttpService) { }

  getFileUrl(fileId: string, resourceId: string): Observable<FileInfo> {
    return this.httpService.get(`files/resources/${resourceId}/files/${fileId}/signedurl`);
  }
}
