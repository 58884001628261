import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ValidationService {

  partials = {};

  isValid = false;
  private validationChangeSubject = new BehaviorSubject(this.isValid);
  validationChange$ = this.validationChangeSubject.asObservable();

  constructor() { }

  updateValidation(isValid: boolean, partialId?: string) {
    if (partialId) {
      this.partials[partialId] = isValid;
      this.isValid = Object.values(this.partials).every(partial => !!partial);
    } else {
      this.isValid = isValid;
    }
    this.validationChangeSubject.next(this.isValid);
  }
}
