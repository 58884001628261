import { Component, Input } from '@angular/core';
import { Table } from 'primeng/table';

@Component({
  selector: 'raily-table-search',
  templateUrl: './table-search.component.html',
  styleUrl: './table-search.component.scss',
})
export class TableSearchComponent {
  @Input() table!: Table;
}
