import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'raily-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrl: './form-modal.component.scss',
})
export class FormModalComponent {

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {}
}
