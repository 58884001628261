import { Injectable } from '@angular/core';
import { BaseApiService } from '../api';
import { BehaviorSubject, firstValueFrom, Subject, tap } from 'rxjs';
import { remove } from 'lodash';

@Injectable()
export class BaseListService<T extends { id: string }, R = T> {

  list: T[] = [];
  private listSubject$ = new BehaviorSubject<T[]>(this.list);
  private refreshSubject$ = new Subject();
  private initialized = false;
  list$ = this.listSubject$.asObservable().pipe(
    tap(() => {
      if (!this.initialized) {
        this.initialized = true;
        this.refresh();
      }
    })
  )

  constructor(
    protected apiService: BaseApiService<T, R>
  ) {
    this.listSubject$.subscribe(list => this.list = list);
  }

  async refresh(query?: any) {
    const entities = await firstValueFrom(this.apiService.find(query));
    this.listSubject$.next(entities);
  }

  async add(entity: T, withRequest?: boolean) {
    // if (withRequest) {
    //   const created = await firstValueFrom(this.apiService.create(entity as R));
    //   this.list.push(created);
    // } else {
      this.list.push(entity as T);
    // }
    this.listSubject$.next(this.list);
  }

  async delete(id: string, withRequest?: boolean) {
    if (withRequest) {
       await firstValueFrom(this.apiService.delete(id));
    }
    const updated = remove(this.list, entity => entity.id === id);
    this.listSubject$.next(updated);
  }

  async update(entity: T, withRequest?: boolean) {
    const index = this.list.findIndex(e => entity.id === e.id);
    // if (withRequest) {
    //   this.list[index] = await firstValueFrom(this.apiService.update(entity.id, entity));
    // } else {
      this.list[index] = entity;
    // }
    this.listSubject$.next(this.list);
  }

  sort(compare: (a: T, b: T) => number) {
    const sorted = this.list.sort(compare);
    this.listSubject$.next(sorted);
  }
}
