export interface AuthData {
  [AuthDataProps.Features]: string[];
  [AuthDataProps.OrganisationId]: string;
  [AuthDataProps.Permissions]: string[];
  [AuthDataProps.Roles]: string;
  [AuthDataProps.Expiry]: number;
  [AuthDataProps.Email]: string;
  [AuthDataProps.Name]: string;
  [AuthDataProps.Surname]: string;
  [AuthDataProps.Token]: string;
}

export enum AuthDataProps {
  Features = 'Raily_Organisation_Features',
  OrganisationId = 'Raily_Organisation_Id',
  Permissions = 'Raily_User_Permissions',
  Roles = 'Raily_User_Roles',
  Expiry = 'exp',
  Email = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
  Name = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  Surname = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
  Token = 'token'
}
