<div class="flex flex-row justify-content-end align-items-center gap-1">
  <raily-table-button
    *ngIf="showButton('details')"
    icon="file"
    weight="thin"
    [outlined]="false"
    (click)="details.emit()">
  </raily-table-button>

  <raily-table-button
    *ngIf="showButton('edit')"
    icon="file-edit"
    weight="thin"
    [outlined]="false"
    (click)="edit.emit()">
  </raily-table-button>

  <raily-table-button
    *ngIf="showButton('delete')"
    icon="trash"
    weight="thin"
    [outlined]="false"
    (click)="delete.emit()">
  </raily-table-button>
</div>

