import { Component, Input } from '@angular/core';

@Component({
  selector: 'raily-center-card',
  templateUrl: './center-card.component.html',
  styleUrls: ['./center-card.component.scss'],
})
export class CenterCardComponent {
  @Input() titleI18n?: string;
  @Input() maxWidth = '1000px';
}
