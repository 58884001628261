import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TranslocoModule } from '@ngneat/transloco';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DisableWhileLoadingDirective } from './directives/disable-while-loading/disable-while-loading.directive';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { ToggleButtonModule } from 'primeng/togglebutton';
import {
  ActiveIconComponent,
  DetailsPageComponent,
  MultilangInputComponent,
  PageLayoutComponent,
  PageHeaderComponent,
  ListActionButtonsComponent,
  InputComponent,
  CenterCardComponent,
  SearchComponent,
  AuditInfoComponent,
  SearchAutocompleteComponent,
} from './components';
import { CalendarModule } from 'primeng/calendar';
import { TableButtonComponent } from './components/ui/table-button/table-button.component';
import { ListPageComponent } from './components/layout/list-page/list-page.component';
import { RouterLink } from '@angular/router';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputMaskModule } from 'primeng/inputmask';
import { SectionTitleComponent } from './components/ui/section-title/section-title.component';
import { SplitterModule } from 'primeng/splitter';
import { TableComponent } from './components/ui/table/table.component';
import { ActionBarDirective } from './directives/action-bar/action-bar.directive';
import { MobilePageComponent } from './components/layout/mobile-page/mobile-page.component';
import { ToastModule } from 'primeng/toast';
import { StationAutocompleteComponent } from './components/ui/station-autocomplete/station-autocomplete.component';
import { TooltipModule } from 'primeng/tooltip';
import { TableSearchComponent } from './components/ui/table-search/table-search.component';
import { ActionButtonsComponent } from './components/layout/action-buttons/action-buttons.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageCarouselComponent } from './components/ui/image-carousel/image-carousel.component';
import { GalleriaModule } from 'primeng/galleria';
import { CompanyInputComponent } from './components/ui/company-input/company-input.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxPermissionsModule,
    AutoCompleteModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    DividerModule,
    DropdownModule,
    DynamicDialogModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    TranslocoModule,
    ProgressSpinnerModule,
    RippleModule,
    TableModule,
    TabViewModule,
    ToastModule,
    ToggleButtonModule,
    TreeTableModule,
    CalendarModule,
    RouterLink,
    RadioButtonModule,
    SelectButtonModule,
    InputMaskModule,
    SplitterModule,
    TooltipModule,
    FileUploadModule,
    GalleriaModule,
    ReactiveFormsModule,
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    NgxPermissionsModule,
    AutoCompleteModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    DividerModule,
    DropdownModule,
    DynamicDialogModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    TranslocoModule,
    ProgressSpinnerModule,
    RippleModule,
    TableModule,
    TabViewModule,
    ToggleButtonModule,
    TreeTableModule,
    FileUploadModule,
    ActiveIconComponent,
    DetailsPageComponent,
    DisableWhileLoadingDirective,
    ListActionButtonsComponent,
    PageHeaderComponent,
    PageLayoutComponent,
    MultilangInputComponent,
    InputComponent,
    CenterCardComponent,
    SearchComponent,
    AuditInfoComponent,
    TableButtonComponent,
    ListPageComponent,
    SearchAutocompleteComponent,
    SectionTitleComponent,
    TableComponent,
    ActionBarDirective,
    MobilePageComponent,
    StationAutocompleteComponent,
    TableSearchComponent,
    ActionButtonsComponent,
    ImageCarouselComponent,
    CompanyInputComponent,
  ],
  declarations: [
    ActiveIconComponent,
    DetailsPageComponent,
    DisableWhileLoadingDirective,
    ListActionButtonsComponent,
    PageHeaderComponent,
    PageLayoutComponent,
    MultilangInputComponent,
    InputComponent,
    CenterCardComponent,
    SearchComponent,
    SearchComponent,
    AuditInfoComponent,
    AuditInfoComponent,
    TableButtonComponent,
    ListPageComponent,
    SearchAutocompleteComponent,
    SectionTitleComponent,
    TableComponent,
    ActionBarDirective,
    MobilePageComponent,
    StationAutocompleteComponent,
    TableSearchComponent,
    ActionButtonsComponent,
    ImageCarouselComponent,
    CompanyInputComponent,
  ],
})
export class SharedModule {}
