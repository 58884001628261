import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { Injectable, isDevMode, ModuleWithProviders, NgModule } from '@angular/core';
import { LanguageService } from '@raily/shared';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
})
export class Transloco {
  static forRoot(): ModuleWithProviders<Transloco> {
    return {
      ngModule: Transloco,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useValue: translocoConfig({
            availableLangs: ['en', 'pl'],
            defaultLang: LanguageService.getSavedLanguage(),
            // Remove this option if your application doesn't support changing language in runtime.
            reRenderOnLangChange: true,
            prodMode: !isDevMode(),
          }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
      ]
    };
  }
}
