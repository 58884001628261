import { NavigationGroup, NavigationItem } from '@raily/core';
import { AppPermissionsValues } from '@raily/auth';
import { ASYNC_NAVIGATION, HttpService, NAVIGATION } from '@raily/shared';
import { combineLatest, map, Observable } from 'rxjs';
import { inject } from '@angular/core';

export const navigationGroups: NavigationGroup[] = [
  {
    name: 'Administration',
    i18nKey: 'NAVIGATION.ADMINISTRATION.GROUP_NAME',
    icon: 'assets/icons/certificate.svg',
    items: [
      {
        name: 'Organization',
        i18nKey: 'NAVIGATION.ADMINISTRATION.ORGANIZATION',
        permissions: [AppPermissionsValues.CoreOrganisationAdministrator],
        link: '/organisation/details/my',
      },
      {
        name: 'Users',
        i18nKey: 'NAVIGATION.ADMINISTRATION.USERS',
        permissions: [AppPermissionsValues.CoreOrganisationAdministrator],
        link: '/users',
      },
      {
        name: 'Roles',
        i18nKey: 'NAVIGATION.ADMINISTRATION.ROLES',
        permissions: [AppPermissionsValues.CoreOrganisationAdministrator,],
        link: '/roles',
      },
      {
        name: 'Document types',
        i18nKey: 'NAVIGATION.ASSETS.DOCUMENT_TYPES',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        link: '/document-types'
      },
      {
        name: 'Create Assets',
        i18nKey: 'NAVIGATION.ASSETS.CREATE',
        permissions: [AppPermissionsValues.AssetManagementCreateAssets],
        link: '/assets/create',
      },
      {
        name: 'Companies',
        i18nKey: 'NAVIGATION.COMPANY.COMPANIES',
        permissions: [],
        link: '/companies',
      },
      {
        name: 'Custom Tables',
        i18nKey: 'NAVIGATION.ADMINISTRATION.DASHBOARDS',
        permissions: [],
        link: '/custom-tables',
      }
    ],
  },
  {
    name: 'Inventory',
    i18nKey: 'NAVIGATION.INVENTORY.GROUP_NAME',
    icon: 'assets/icons/dashboard.svg',
    items: [
      {
        name: 'Inventory groups',
        i18nKey: 'NAVIGATION.INVENTORY.ITEM_GROUPS',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        link: '/item-groups',
      },
      {
        name: 'Item templates',
        i18nKey: 'NAVIGATION.INVENTORY.SPARE_PARTS',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        link: '/spare-parts',
      },
      {
        name: 'Warehouses',
        i18nKey: 'NAVIGATION.INVENTORY.WAREHOUSES',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        link: '/warehouses',
      },
      {
        name: 'Stock',
        i18nKey: 'NAVIGATION.INVENTORY.STOCK_ITEMS',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        link: '/stock-items',
      },
      {
        name: 'Material movements',
        i18nKey: 'NAVIGATION.INVENTORY.MATERIAL_MOVEMENTS',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        link: '/material-movements',
      }
    ],
  },
  {
    name: 'Templates',
    i18nKey: 'NAVIGATION.TEMPLATES.GROUP_NAME',
    icon: 'assets/icons/dashboard.svg',
    items: [
      {
        name: 'Asset Templates',
        i18nKey: 'NAVIGATION.TEMPLATES.ASSET_TEMPLATES',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        link: '/asset-templates',
      },
      {
        name: 'Elements',
        i18nKey: 'NAVIGATION.TEMPLATES.ELEMENTS',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        link: '/elements',
      },
      {
        name: 'Attributes',
        i18nKey: 'NAVIGATION.TEMPLATES.ATTRIBUTES',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        link: '/attributes',
      },
      {
        name: 'Dictionaries',
        i18nKey: 'NAVIGATION.TEMPLATES.DICTIONARIES',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        link: '/dictionaries',
      },
      {
        name: 'Maintenance steps',
        i18nKey: 'NAVIGATION.MAINTENANCE.MAINTENANCE_STEPS',
        permissions: [AppPermissionsValues.DamageReportsRead],
        link: '/maintenance-steps',
      }
    ],
  },
  {
    name: 'Assets',
    i18nKey: 'NAVIGATION.ASSETS.GROUP_NAME',
    icon: 'assets/icons/dashboard.svg',
    items: [
      {
        name: 'Locomotives',
        i18nKey: 'NAVIGATION.ASSETS.LOCOMOTIVES',
        permissions: [AppPermissionsValues.AssetManagementAssetsRead],
        link: '/assets/list/Locomotive',
      },
      {
        name: 'Wagons',
        i18nKey: 'NAVIGATION.ASSETS.WAGONS',
        permissions: [AppPermissionsValues.AssetManagementAssetsRead],
        link: '/assets/list/Wagon',
      },
      {
        name: 'Wagon damages',
        i18nKey: 'NAVIGATION.ASSETS.REPORTED_DAMAGES',
        permissions: [AppPermissionsValues.AssetManagementAssetsRead],
        link: '/damage-reports/wagons',
      },
      {
        name: 'Wheelsets',
        i18nKey: 'NAVIGATION.ASSETS.WHEELSETS',
        permissions: [AppPermissionsValues.AssetManagementAssetsRead],
        link: '/assets/list/Wheelset',
      },
      {
        name: 'Telematics',
        i18nKey: 'NAVIGATION.ASSETS.TELEMATICS',
        permissions: [AppPermissionsValues.AssetManagementAssetsRead],
        link: '/assets/telematics',
      },
    ],
  },
  {
    name: 'Maintenance',
    i18nKey: 'NAVIGATION.MAINTENANCE.GROUP_NAME',
    icon: 'assets/icons/dashboard.svg',
    mobile: true,
    items: [
      {
        name: 'Damage reports',
        i18nKey: 'NAVIGATION.MAINTENANCE.DAMAGE_REPORTS',
        permissions: [AppPermissionsValues.DamageReportsRead],
        link: '/damage-reports',
        mobile: true,
        exactMatch: true,
      },
      {
        name: 'Create damage report',
        i18nKey: 'NAVIGATION.MAINTENANCE.CREATE_DAMAGE_REPORT',
        permissions: [AppPermissionsValues.DamageReportsRead],
        link: '/damage-reports/create',
        mobile: true,
        exactMatch: true,
      },
      {
        name: 'Orders',
        i18nKey: 'NAVIGATION.MAINTENANCE.ORDERS',
        permissions: [AppPermissionsValues.DamageReportsRead],
        link: '/maintenance-orders',
      }
    ],
  },
  {
    name: 'Transport',
    i18nKey: 'NAVIGATION.TRANSPORT.GROUP_NAME',
    icon: 'assets/icons/dashboard.svg',
    mobile: true,
    items: [
      {
        name: 'Train composition',
        i18nKey: 'NAVIGATION.TRANSPORT.TRAIN_COMPOSITION',
        permissions: [AppPermissionsValues.DamageReportsRead],
        link: '/train-composition',
        mobile: true,
        exactMatch: true
      },
      {
        name: 'Create train composition',
        i18nKey: 'NAVIGATION.TRANSPORT.CREATE_TRAIN_COMPOSITION',
        permissions: [AppPermissionsValues.DamageReportsRead],
        link: '/train-composition/create',
        mobile: true,
        exactMatch: true
      },
    ],
  },
];

export const navigationProvider = {
  provide: NAVIGATION,
  useValue: navigationGroups
};

export function asyncNavigationResolver(): Observable<NavigationGroup[]> {

  interface CustomTableListElement {
    id: string;
    name: string;
    elementName: string;
    tag: string;
  }

  const http = inject(HttpService);

  return combineLatest([
    http.get<CustomTableListElement[]>('assets/custom-tables')
  ]).pipe(
    map(([customTables]) => {

      const dashboardsGroup: NavigationGroup = {
        name: 'NAVIGATION.DASHBOARDS.GROUP_NAME',
        icon: 'assets/icons/dashboard.svg',
        mobile: false,
        order: 2,
        items: customTables.map(customTable => ({
          name: customTable.name,
          i18nKey: customTable.name,
          permissions: [],
          link: `assets/dashboard/${customTable.id}`,
        }))
      };

      return [dashboardsGroup];
    })
  )
}

export const asyncNavigationProvider = {
  provide: ASYNC_NAVIGATION,
  useValue: asyncNavigationResolver
};
