import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services'
import { take, tap } from 'rxjs';

export const loggedInGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.loggedIn$.pipe(
    take(1),
    tap(loggedIn => {
      if (!loggedIn) {
        router.navigate(['auth/login'])
      }
    })
  )
}
