import { Directive, OnDestroy, TemplateRef } from '@angular/core';
import { LayoutService } from '../../services';

@Directive({
  selector: '[railyActionBar]',
})
export class ActionBarDirective implements OnDestroy {
  constructor(
    private templateRef: TemplateRef<any>,
    private layoutService: LayoutService
  ) {
    this.layoutService.setActionBarTemplate(templateRef);
  }

  ngOnDestroy(): void {
    this.layoutService.setActionBarTemplate(null);
  }
}
