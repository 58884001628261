<div class="container surface-section px-3 md:px-4 lg:px-6 pt-3 md:pt-4 pb-1 md:pb-3 sticky top-0 right-0 left-0">
  <div class="flex align-items-center justify-content-between flex-row pb-3 border-bottom-1 border-primary-700">
    <div class="flex align-items-center">
<!--      <i class="pi pi-inbox text-2xl mr-3 text-500"></i>-->
      <span class="header-text text-3xl font-medium text-900">{{ title | transloco }}</span>
    </div>
    <div class="md:mt-0 flex flex-row justify-content-end gap-3">
      <ng-content></ng-content>
    </div>
  </div>
</div>
