import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { LayoutService } from '../../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'raily-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {
  @Input() title?: string | null;
  @Input() backButton: 'none' | 'location' | 'router' = 'none';

  constructor(
    public layoutService: LayoutService,
    private location: Location,
    private router: Router
  ) {}

  back() {
    switch (this.backButton) {
      case 'location':
        this.location.back();
        break;
      case 'router':
        this.router.navigate([`/${this.router.url.split('/')[1]}`]);
        break;
    }
  }
}
