import { Routes } from '@angular/router';

//TODO: fix typings
export function generateScreenRoutes(listComponentClass?: any, detailsComponentClass?: any): Routes {
  return [
    { path: '', component: listComponentClass },
    { path: 'create', component: detailsComponentClass, data: { mode: 'create' } },
    { path: 'details/:id', component: detailsComponentClass, data: { mode: 'details' } },
    { path: 'edit/:id', component: detailsComponentClass, data: { mode: 'edit' } },
  ];
}
