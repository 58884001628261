import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'raily-table-button',
  templateUrl: './table-button.component.html',
  styleUrls: ['./table-button.component.scss'],
})
export class TableButtonComponent {
  @Input() icon?: string;
  @Input() disabled?: boolean;
  @Input() outlined = true;
  @Input() weight = 'thick';
  @Output() onClick = new EventEmitter<void>();
}
