import { Component, Inject, Injector, OnInit, runInInjectionContext } from '@angular/core';
import { NavigationGroup, } from '../../model';
import { AuthService } from '@raily/auth';
import { ASYNC_NAVIGATION, LayoutService, NAVIGATION } from '@raily/shared';
import { map, Observable, shareReplay, switchMap, combineLatest, startWith } from 'rxjs';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'raily-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  visibleNavigation$: Observable<NavigationGroup[]>;

  constructor(
    public authService: AuthService,
    public layoutService: LayoutService,
    private injector: Injector,
    @Inject(NAVIGATION) public navigation: NavigationGroup[],
    @Inject(ASYNC_NAVIGATION) public asyncNavigationResolver: () => Observable<NavigationGroup[]>
  ) {
    this.visibleNavigation$ = combineLatest([
      this.layoutService.isMobile$.pipe(shareReplay(1)),
      this.layoutService.refreshSidebar$.pipe(
        switchMap(() => runInInjectionContext(injector, () => this.asyncNavigationResolver()).pipe(startWith([])))
      ),
    ]).pipe(
      map(([isMobile, asyncGroups]) => {
        const navigation = cloneDeep(this.navigation);
        for (const asyncGroup of asyncGroups) {
          navigation.splice(asyncGroup.order!, 0, asyncGroup);
        }
        if (isMobile) {
          return navigation.filter(nav => nav.mobile).map(nav => {
            nav.items = nav.items.filter(item => item.mobile);
            return nav;
          });
        } else {
          return navigation;
        }
      })
    );
  }

  onLogout() {
    this.authService.logout();
  }

  isPrimeIcon(icon: string) {
    return icon && icon.includes('pi ');
  }
}
