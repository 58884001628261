import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { get } from 'lodash';

@Component({
  selector: 'raily-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnChanges {
  @Input() placeholder = 'Search';
  @Input() values: any[] = [];
  @Input() paths?: string[];
  @Output() filtered = new EventEmitter<any[]>();
  search = '';

  ngOnChanges() {
    this.onSearchChange(this.search);
  }

  onSearchChange(search: string) {
    this.search = search;

    if (!search) {
      this.filtered.emit(this.values);
      return;
    }

    if (!this.paths) {
      this.filtered.emit(this.values.filter(value => value.toLowerCase().includes(search.toLowerCase())));
      return;
    }

    this.filtered.emit(this.values.filter(item => {
      for (const path of this.paths!) {
        const value = get(item, path);
        if (value && value.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
      }
      return false;
    }));
  }

  clear() {
    this.onSearchChange('');
  }
}
