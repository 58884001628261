import { LanguageService } from '../services/language/language.service';

export enum AssetType {
  Wagon = 'Wagon',
  Locomotive = 'Locomotive',
  Wheelset = 'Wheelset'
}

const translation = {
  [AssetType.Wagon]: 'FEATURE.ASSET.TYPE.WAGON',
  [AssetType.Locomotive]: 'FEATURE.ASSET.TYPE.LOCOMOTIVE',
  [AssetType.Wheelset]: 'FEATURE.ASSET.TYPE.WHEELSET'
};

LanguageService.registerEnum(AssetType, translation);
