import { Component, EventEmitter, input, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BaseDetailsService, BaseListService, LanguageService, ValidationService } from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, firstValueFrom, map, Observable, startWith } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { toObservable } from '@angular/core/rxjs-interop';
import { PageLayoutComponent } from '../page-layout/page-layout.component';

@UntilDestroy()
@Component({
  selector: 'raily-details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.scss'],
})
export class DetailsPageComponent implements OnInit, OnDestroy {
  @ViewChild('layoutComponent') pageLayoutComponent!: PageLayoutComponent;
  title = input<string>();
  nameI18n = input<string>();
  @Input() detailsService!: BaseDetailsService<any, any>;
  @Input() listService?: BaseListService<any>;
  @Input() showEditButton = true;
  @Input() saveOverride?: () => any;
  @Output() beforeSave = new EventEmitter<any>();
  mode!: string;

  resolvedTitle$: Observable<string> = combineLatest([
    toObservable(this.title).pipe(startWith(undefined)),
    toObservable(this.nameI18n).pipe(startWith(undefined)),
    this.languageService.translationsLoaded$
  ]).pipe(
    map(([title, nameI18n]) => {
      if (title) {
        return title;
      }
      if (!nameI18n || !this.mode) {
        return '';
      }
      const name = this.languageService.translate(nameI18n);
      switch (this.mode) {
        case 'details':
          return name;
        case 'edit':
          return `${this.languageService.translate('DETAILS.EDIT')} ${name}`;
        case 'create':
          return `${this.languageService.translate('DETAILS.CREATE')} ${name}`;
        default:
          return '';
      }
    })
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
    public validationService: ValidationService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.mode = data['mode'];
      this.detailsService.modeSubject$.next(this.mode)
    });

    this.route.params.pipe(untilDestroyed(this)).subscribe(params =>
      this.detailsService.idSubject$.next(params['id'])
    );
  }

  ngOnDestroy(): void {
    this.detailsService.idSubject$.next(undefined);
  }

  async save() {
    this.beforeSave.emit();
    if (this.saveOverride) {
      this.saveOverride();
      return;
    }
    const saved = await firstValueFrom(this.detailsService.save());
    if (this.listService) {
      this.listService.refresh();
    }
    this.router.navigate([this.route.parent?.snapshot.url[0].path, 'details', saved.id], { queryParamsHandling: 'merge' })
  }

  async cancel() {
    const mode = await firstValueFrom(this.detailsService.mode$);
    const id = await firstValueFrom(this.detailsService.id$);

    if (mode === 'edit') {
      this.router.navigate([this.route.parent?.snapshot.url[0].path, 'details', id], { queryParamsHandling: 'merge' });
    }
    if (mode === 'details' || mode === 'create') {
      this.router.navigate([this.route.parent?.snapshot.url[0].path]);
    }
  }

  async edit() {
    const id = await firstValueFrom(this.detailsService.id$);
    this.router.navigate([this.route.parent?.snapshot.url[0].path, 'edit', id], { queryParamsHandling: 'merge' });
  }
}
