import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingStateService {

  private loadingSubject$ = new BehaviorSubject(false);
  loading$ = this.loadingSubject$.asObservable();

  setLoadingState(loading: boolean) {
    this.loadingSubject$.next(loading);
  }
}
