import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor, FormControl, NgControl,
  ValidationErrors,
} from "@angular/forms";
import { OrganizationSettings, TranslatedProperty } from '../../../model';
import { ORGANIZATION_SETTINGS } from '../../../di';
import { getValidationClasses } from '../../../util/validation';

@Component({
  selector: 'raily-multilang-input',
  templateUrl: './multilang-input.component.html',
  styleUrls: ['./multilang-input.component.scss'],
})
export class MultilangInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() required = false;
  @Input() label!: string;
  @Input() type = 'text';
  @Input() showDivider = false;

  value?: TranslatedProperty;
  isDisabled = false;
  translationsState: { [key: string]: { code: string, active: boolean, value: string } } = {};

  formControl!: FormControl;

  onChange = (_: TranslatedProperty) => {};
  onTouch = () => {};

  get translationStates(): { code: string, active: boolean, value: string }[] {
    return Object.keys(this.translationsState).map(key => this.translationsState[key]);
  }

  get activeTranslations() {
    return this.translationStates.filter(translation => translation.active);
  }

  constructor(
    @Inject(ORGANIZATION_SETTINGS) public organizationSettings: OrganizationSettings,
    @Optional() @Self() public ngControl: NgControl,
    private cdr: ChangeDetectorRef
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    for (const lang of this.organizationSettings.langs) {
      this.translationsState[lang] = { code: lang, active: false, value: '' };
    }

    if (!this.value) {
      this.onChange({ translations: [] });
    }
  }

  ngAfterViewInit(): void {
    this.formControl = this.ngControl?.control as FormControl;
    if (this.required) {
      this.formControl.addValidators(this.validateRequired.bind(this));
    }
    this.cdr.detectChanges();
  }

  validateRequired(control: AbstractControl<any, any>): ValidationErrors | null {
    const value: TranslatedProperty = control.value;
    const required = { required: true };
    if (!value) {
      return required;
    }
    const translation = value.translations.find(translation => translation.code === this.organizationSettings.defaultLang);
    if (!translation || !translation.value) {
      return required;
    }
    return null;
  }

  validationClasses(code: string) {
    return code === this.organizationSettings.defaultLang ? getValidationClasses(this.formControl) : {};
  }

  onValueChange(code: string, value: string) {
    this.translationsState[code].value = value;
    const updated: TranslatedProperty = { translations: [] };
    for (const translated of this.translationStates) {
      updated.translations.push({ code: translated.code, value: translated.value });
    }
    this.onChange(updated);
  }

  writeValue(value: TranslatedProperty): void {
    this.value = value;
    for (const translation of this.value?.translations || []) {
      this.translationsState[translation.code] = {
        code: translation.code,
        active: this.translationsState[translation.code]?.active || !!translation.value || translation.code === this.organizationSettings.defaultLang,
        value: translation.value
      }
    }
    this.translationsState[this.organizationSettings.defaultLang].active = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    // Add additional logic if you need to disable specific elements inside your component
  }
}
