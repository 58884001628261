<div class="surface-ground min-w-screen min-h-screen flex justify-content-center align-items-center">
  <div class="login-card card flex w-10 shadow-3">
    <div class="surface-overlay w-full lg:w-6 p-6 md:p-8">
      <div class="mb-3">
        <img src="assets/logo.svg" alt="Image" height="50" class="mb-3">
      </div>
      <div>
        <ng-container [ngSwitch]="viewState">
          <ng-container *ngSwitchCase="LoginViewState.Login">
            <label for="email" class="block text-900 font-medium mb-2">{{ 'FEATURE.LOGIN.EMAIL' | transloco }}</label>
            <input [(ngModel)]="username" id="email" type="text" pInputText class="w-full mb-3 p-3">

            <label for="password" class="block text-900 font-medium mb-2">{{ 'FEATURE.LOGIN.PASSWORD' | transloco }}</label>
            <input [(ngModel)]="password" id="password" type="password" pInputText class="w-full mb-5 p-3">

            <p-button
              railyDisableWhileLoading
              icon="pi pi-sign-in"
              class="w-full block"
              [label]="'FEATURE.LOGIN.SIGN_IN' | transloco"
              (click)="login()">
            </p-button>

            <div class="w-full flex flex-row justify-content-end mt-3">
              <span class="text-sm underline cursor-pointer text-color-secondary" (click)="forgotPassword()">{{ 'FEATURE.LOGIN.FORGOT_PASSWORD' | transloco }}</span>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="LoginViewState.ForgotPassword">
            <h3 class="mb-4">{{ 'FEATURE.LOGIN.PASSWORD_RECOVERY' | transloco }}</h3>
            <label for="email" class="block text-900 font-medium mb-2">{{ 'FEATURE.LOGIN.EMAIL' | transloco }}</label>
            <input [(ngModel)]="username" id="forgot-password-email" type="text" pInputText class="w-full mb-3 p-3">

            <p-button
              railyDisableWhileLoading
              icon="pi pi-refresh"
              class="w-full"
              [label]="'FEATURE.LOGIN.SEND_REQUEST' | transloco"
              (click)="forgotPasswordRequest()">
            </p-button>
          </ng-container>

          <ng-container *ngSwitchCase="LoginViewState.ForgotPasswordSuccess">
            <div class="w-full h-full flex flex-column justify-content-center align-items-center mb-6">
              <h3 class="mb-4">{{ 'FEATURE.LOGIN.PASSWORD_RECOVERY_SUCCESS' | transloco }}</h3>
              <span class="text-center">{{ 'FEATURE.LOGIN.PASSWORD_RECOVERY_SUCCESS_INFO' | transloco }}</span>
            </div>

            <p-button
              railyDisableWhileLoading
              icon="pi pi-chevron-circle-left"
              class="w-full block"
              [label]="'BUTTON.BACK' | transloco"
              (click)="back()">
            </p-button>
          </ng-container>

          <ng-container *ngSwitchCase="LoginViewState.ResetPassword">
            <label for="email" class="block text-900 font-medium mb-2">{{ 'FEATURE.LOGIN.NEW_PASSWORD' | transloco }}</label>
            <input [(ngModel)]="password" id="new-password" type="password" pInputText class="w-full mb-3 p-3">

            <label for="password" class="block text-900 font-medium mb-2">{{ 'FEATURE.LOGIN.REPEAT_PASSWORD' | transloco }}</label>
            <input [(ngModel)]="repeatPassword" id="repeat-password" type="password" pInputText class="w-full mb-5 p-3">

            <p-button
              railyDisableWhileLoading
              icon="pi pi-sign-in"
              class="w-full block"
              [disabled]="!password || !repeatPassword || password !== repeatPassword"
              [label]="'FEATURE.LOGIN.RESET_PASSWORD' | transloco"
              (click)="resetPassword()">
            </p-button>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="hidden lg:block w-6 bg-no-repeat bg-cover" style="background-image: url('assets/trains-login-page.webp')"></div>
  </div>
</div>

