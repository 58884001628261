import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageService } from '@raily/shared';

@Component({
  selector: 'raily-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {

  constructor(private translocoService: TranslocoService, public languageService: LanguageService) {}

  onLanguageChange(lang: string) {
    this.languageService.changeLanguage(lang);
  }
}
