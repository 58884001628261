import { Component, Input } from '@angular/core';

@Component({
  selector: 'raily-active-icon',
  templateUrl: './active-icon.component.html',
  styleUrls: ['./active-icon.component.scss'],
})
export class ActiveIconComponent {
  @Input() active?: boolean;
}
