import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  currentTab= 0;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.router.parseUrl(this.router.url).queryParams['tab']),
      map(tab => tab ?? 0),
      map(tab => parseInt(tab))
    ).subscribe(tab => {
      this.currentTab = tab;
    })
  }

  updateTab(index: number): void {
    this.router.navigate([], {
      queryParams: { tab: index },
      queryParamsHandling: 'merge'
    });
  }
}
