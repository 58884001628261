import {
  AfterContentInit, AfterViewInit, ChangeDetectorRef,
  Component,
  ContentChildren,
  Input, OnChanges,
  Optional,
  QueryList,
  Self, SimpleChanges
} from '@angular/core';
import {
  ControlValueAccessor, FormControl, NgControl, Validators
} from '@angular/forms';
import { CalendarTypeView } from 'primeng/calendar';
import { LanguageService, LayoutService } from '../../../services';
import { BehaviorSubject, delay, Observable } from 'rxjs';
import { dateToIso, formatDate } from '../../../util';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { TooltipOptions } from 'primeng/api';
import { getValidationClasses } from '../../../util/validation';
import { SelectOptions } from '../../../model';

@Component({
  selector: 'raily-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnChanges, AfterContentInit, AfterViewInit, ControlValueAccessor {
  @Input() required = false;
  // hack for required validation check bug when disableSwitch is used
  @Input()
  set isRequired(value: boolean) {
    this.required = value;
  }
  @Input() label?: string;
  @Input() type = 'text';
  @Input() showDivider = false;
  @Input() disableSwitch = false;
  @Input() options?: any[] = [];
  @Input() optionLabel?: string;
  @Input() optionValue?: string;
  @Input() placeholder?: string;
  @Input() radioLayout = 'horizontal';
  @Input() mask?: string;
  @Input() autocompleteSearchKeys: string[] = [];
  @Input() tooltip?: string;
  @Input() tooltipOptions?: TooltipOptions;
  @Input() showClear?: boolean;
  @Input() rawText?: boolean;
  @Input() attributeDateFormat? = 'DD/MM/YYYY';
  @Input() enumSelect?: any;
  @Input() includeEnumValues?: any[];

  value?: any;
  isDisabled = false;
  hasContent = false;
  _disableSwitchState = false;

  private valueChangesSubject = new BehaviorSubject(this.value);
  valueChanges: Observable<any> = this.valueChangesSubject.pipe(
    delay(1)
  );

  suggestions: any[] = [];

  defaultTooltipOptions: TooltipOptions = {
    showDelay: 200,
    tooltipPosition: 'right'
  };

  currencies: SelectOptions = [
    { label: 'PLN', value: 'PLN' },
    { label: 'EUR', value: 'EUR' },
    { label: 'USD', value: 'USD' },
  ];

  formControl!: FormControl;

  get disableSwitchState() {
    return this._disableSwitchState;
  }

  set disableSwitchState(value: boolean) {
    this._disableSwitchState = value;
    if (!value) {
      this.onValueChange(null);
    }
  }

  get finalDisabledStatus() {
    return this.isDisabled || (this.disableSwitch && !this.disableSwitchState);
  }

  get dateFormat() {
    switch (this.attributeDateFormat) {
      case 'DD/MM/YYYY': return 'dd/mm/yy';
      case 'MM/YYYY': return 'mm/yy';
      case 'YYYY': return 'yy';
      default: return 'dd/mm/yy';
    }
  }

  get dateType(){
    switch (this.attributeDateFormat) {
      case 'DD/MM/YYYY': return  'day' as CalendarTypeView;
      case 'MM/YYYY': return 'month' as CalendarTypeView;
      case 'YYYY': return 'year' as CalendarTypeView;
      default: return 'day' as CalendarTypeView;
    }
  }

  get finalTooltipOptions() {
    return { ...this.defaultTooltipOptions, ...(this.tooltipOptions ?? {}) };
  }

  get validationClasses() {
    return getValidationClasses(this.formControl);
  }

  onChange = (_: any) => {};
  onTouch = () => {};

  @ContentChildren('content') content!: QueryList<any>;

  constructor(
    public layoutService: LayoutService,
    @Optional() @Self() public ngControl: NgControl,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['includeEnumValues']) {
      this.setupEnumOptions();
    }
  }

  ngAfterViewInit(): void {
    this.formControl = this.ngControl?.control as FormControl;
    if (this.required) {
      this.formControl.addValidators(Validators.required);
    }
    if (this.enumSelect) {
      this.setupEnumOptions();
    }
    this.cdr.detectChanges();
  }

  ngAfterContentInit() {
    this.hasContent = this.content && this.content.length > 0;
  }

  writeValue(value: any): void {
    if (value === undefined) {
      this.value = null;
      return;
    }
    if (this.type === 'date' && value) {
      this.value = formatDate(value, this.attributeDateFormat ?? 'DD/MM/YYYY');
    } else {
      this.value = value;
    }
    if (this.value) {
      this.disableSwitchState = true;
    }
  }

  onValueChange(value: any) {
    this.value = value;
    const output = (this.type === 'date' && value) ? dateToIso(value) : value;
    this.onChange(output);
    this.valueChangesSubject.next(output);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  search(event: AutoCompleteCompleteEvent) {
    this.suggestions = (this.options && this.options.filter(option => {
      return this.autocompleteSearchKeys.map(key => option[key]).some(value => value.toLowerCase().includes(event.query.toLowerCase()))
    })) ?? [];
  }

  onNumberInputFocus() {
    if (this.value === 0) {
      this.value = null;
    }
  }

  private setupEnumOptions() {
    this.languageService.getEnumOptions(this.enumSelect).subscribe(options => {
      this.options = options;
      if (this.includeEnumValues) {
        this.options = this.options.filter(option => this.includeEnumValues!.includes(option.value));
      }
    })
  }
}
