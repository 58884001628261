import { ORGANIZATION_SETTINGS, OrganizationSettings } from '@raily/shared';

const organizationSettings: OrganizationSettings = {
  name: 'Coleye',
  langs: ['en', 'pl', 'de'],
  defaultLang: 'en'
}

export const organizationSettingsProvider = {
  provide: ORGANIZATION_SETTINGS,
  useValue: organizationSettings
}

