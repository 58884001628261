<div class="table-card" [class.fixed-layout]="fixedLayout">
  <p-table
    #table
    [value]="items"
    [scrollable]="true"
    [globalFilterFields]="filterFields"
    [rowTrackBy]="trackBy"
    [scrollHeight]="finalScrollHeight()">

    <ng-template pTemplate="caption">
      <div class="flex align-items-center">
        <span *ngIf="title" class="table-title">{{ title | transloco }}</span>
        <div class="ml-auto flex flex-row gap-2 align-items-center">
          <ng-content select="[actions]"></ng-content>
          <raily-table-search [table]="table"></raily-table-search>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let column of columns"
          [ngClass]="{ 'table-icon': getColumnType(column) === ColumnType.Boolean }"
          [class]="column.width ? 'w-' + column.width : ''"
          [pSortableColumn]="(sortable && getColumnSortable(column)) ? column.propertyPath : undefined"
        >
          <div class="flex flex-row align-items-center">
            {{ column.titleI18n | transloco }}<p-sortIcon *ngIf="sortable && getColumnSortable(column)" [field]="column.propertyPath" />
          </div>
        </th>

        <th *ngIf='showButtonsColumn'></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr style="height:46px">

        <td
          class="table-cell"
          *ngFor="let column of columns; let index = index"
          [ngClass]="{ 'table-icon': getColumnType(column) === ColumnType.Boolean, 'cursor-pointer': column.callback }"
          [class]="'w-' + column.width"
          (click)="column.callback && column.callback(item, index, column)"
          [pEditableColumn]="editable && getValue(item, column.propertyPath)"
        >
          <p-cellEditor *ngIf="editable">
            <ng-template pTemplate="input">
              <input
                pInputText
                type="text"
                [disabled]="column.editDisabled ?? false"
                [(ngModel)]="item[column.propertyPath]"
                (keydown.enter)="onCellEdit(item, column)"
                (blur)="onCellEdit(item, column)"/>
            </ng-template>
            <ng-template pTemplate="output">
              {{ (column.transform ? column.transform(getValue(item, column.propertyPath)) : getValue(item, column.propertyPath)) ?? '-' }}
            </ng-template>
          </p-cellEditor>

          <ng-container *ngIf="!editable" [ngSwitch]="getColumnType(column)">
            <span *ngSwitchCase="ColumnType.Text">
              <ng-container *ngIf="column.isTranslated">
                  {{ (column.transform ? column.transform(column.translationPath + getValue(item, column.propertyPath)) : column.translationPath + getValue(item, column.propertyPath) ?? '-') | transloco}}
              </ng-container>

              <ng-container *ngIf="!column.isTranslated">
                  {{ (column.transform ? column.transform(getValue(item, column.propertyPath)) : getValue(item, column.propertyPath)) ?? '-' }}
              </ng-container>
            </span>

            <raily-active-icon
              *ngSwitchCase="ColumnType.Boolean"
              [active]="getValue(item, column.propertyPath)"
            ></raily-active-icon>

            <span *ngSwitchCase="ColumnType.Date">
              {{ (getValue(item, column.propertyPath) | date:(column.dateFormat ?? 'dd/MM/yy')) ?? '-' }}
            </span>
          </ng-container>
        </td>

        <td *ngIf='showButtonsColumn'>
          <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
