<div *ngIf="formControl" class="flex flex-column" [ngClass]="{ divider: showDivider }">

  <div *ngIf="label" class="flex flex-row justify-content-between mb-2 z-2">
    <label [for]="label" class="font-medium text-900">
      {{ label | transloco }}
      <span *ngIf="required && (disableSwitch ? disableSwitchState : true)">*</span>
      <i
        *ngIf="tooltip"
        class="tooltip-icon ml-2 pi pi-info-circle"
        [pTooltip]="tooltip ? (tooltip | transloco) : ''"
        [tooltipOptions]="finalTooltipOptions">
      </i>
    </label>
    <p-inputSwitch *ngIf="disableSwitch" [disabled]="isDisabled" [(ngModel)]="disableSwitchState"></p-inputSwitch>
  </div>

  <ng-container *ngIf="!hasContent; else content" [ngSwitch]="type">

    <ng-container *ngSwitchCase="'text'">

      <ng-template #rawTextTemplate>
        <div class="raw-text">{{ value }}</div>
      </ng-template>

      <ng-container *ngIf="!rawText; else rawTextTemplate">
        <input
          *ngIf="!mask; else maskInput"
          type="text"
          pInputText
          [id]="label"
          [name]="label!"
          [required]="required"
          [disabled]="finalDisabledStatus"
          [placeholder]="placeholder ?? ''"
          [ngClass]="validationClasses"
          [ngModel]="value"
          (ngModelChange)="onValueChange($event)">
        <ng-template #maskInput>
          <p-inputMask
            type="text"
            [id]="label"
            [name]="label!"
            [required]="required"
            [disabled]="finalDisabledStatus"
            [placeholder]="placeholder ?? ''"
            [ngClass]="validationClasses"
            [mask]="mask!"
            [autoClear]="false"
            [ngModel]="value"
            [autocomplete]="'off'"
            (ngModelChange)="onValueChange($event)">
          </p-inputMask>
        </ng-template>
      </ng-container>

    </ng-container>

    <ng-container *ngSwitchCase="'number'">
      <input
        type="number"
        pInputText
        [id]="label"
        [name]="label!"
        [required]="required"
        [disabled]="finalDisabledStatus"
        [placeholder]="placeholder ?? ''"
        [ngClass]="validationClasses"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
        (focus)="onNumberInputFocus()">
    </ng-container>

    <ng-container *ngSwitchCase="'textarea'">
      <textarea
        pInputTextarea
        [id]="label"
        [name]="label!"
        [required]="required"
        [disabled]="finalDisabledStatus"
        [placeholder]="placeholder ?? ''"
        [ngClass]="validationClasses"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)">
      </textarea>
    </ng-container>

    <ng-container *ngSwitchCase="'select'">
      <p-dropdown
        [id]="label!"
        [name]="label!"
        [required]="required"
        [disabled]="finalDisabledStatus"
        [placeholder]="placeholder ?? ''"
        [ngClass]="validationClasses"
        [options]="options"
        [optionLabel]="optionLabel"
        [optionValue]="optionValue"
        [autoDisplayFirst]="false"
        [showClear]="!required"
        appendTo="body"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)">
      </p-dropdown>
    </ng-container>

    <ng-container *ngSwitchCase="'multiselect'">
      <p-multiSelect
        [id]="label!"
        [name]="label!"
        [required]="required"
        [disabled]="finalDisabledStatus"
        [placeholder]="placeholder ?? ''"
        [ngClass]="validationClasses"
        [options]="options"
        [optionLabel]="optionLabel"
        [optionValue]="optionValue"
        [showClear]="!required"
        appendTo="body"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)">
      </p-multiSelect>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
      <p-checkbox
        [id]="label"
        [name]="label!"
        [required]="required"
        [disabled]="finalDisabledStatus"
        [binary]="true"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)">
      </p-checkbox>
    </ng-container>

    <ng-container *ngSwitchCase="'radio'">
      <div class="flex gap-3" [ngClass]="{ 'flex-row': radioLayout === 'horizontal', 'flex-column': radioLayout === 'vertical' }">
        <div *ngFor="let option of options ?? []" class="flex align-items-center">
          <p-radioButton
            [name]="option.value"
            [disabled]="finalDisabledStatus"
            [inputId]="option.value"
            [value]="option.value"
            [ngClass]="validationClasses"
            [ngModel]="value"
            (ngModelChange)="onValueChange($event)">
          </p-radioButton>
          <label class="ml-2" [for]="option.value">{{ option.label | transloco }}</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'select-button'">
      <p-selectButton
        [id]="label"
        [name]="label!"
        [required]="required"
        [disabled]="finalDisabledStatus"
        [options]="options"
        [optionLabel]="optionLabel"
        [optionValue]="optionValue"
        [ngClass]="validationClasses"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)">
      </p-selectButton>
    </ng-container>

    <ng-container *ngSwitchCase="'toggle'">
      <p-inputSwitch
        [id]="label"
        [name]="label!"
        [disabled]="finalDisabledStatus"
        [pTooltip]="tooltip ? (tooltip | transloco) : ''"
        [ngClass]="validationClasses"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)">
      </p-inputSwitch>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <p-calendar
        [id]="label"
        [name]="label!"
        [required]="required"
        [disabled]="finalDisabledStatus"
        [ngClass]="validationClasses"
        [showIcon]="!finalDisabledStatus"
        [view]="dateType"
        [dateFormat]="dateFormat"
        [dataType]="'string'"
        [touchUI]="(layoutService.isMobile$ | async) ?? false"
        appendTo="body"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)">
      </p-calendar>
    </ng-container>

    <ng-container *ngSwitchCase="'autocomplete'">
      <p-autoComplete
        [id]="label!"
        [name]="label!"
        [required]="required"
        [disabled]="finalDisabledStatus"
        [pTooltip]="tooltip ? (tooltip | transloco) : ''"
        [tooltipOptions]="finalTooltipOptions"
        [ngClass]="validationClasses"
        [dropdown]="true"
        [delay]="100"
        [showClear]="showClear"
        [suggestions]="suggestions"
        [optionLabel]="optionLabel!"
        [optionValue]="optionValue!"
        [styleClass]="'w-12'"
        [inputStyleClass]="'w-12'"
        appendTo="body"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
        (completeMethod)="search($event)">
      </p-autoComplete>
    </ng-container>

    <ng-container *ngSwitchCase="'currency'">
      <raily-input
        type="select"
        [label]="'FEATURE.MAINTENANCE_ORDER.WORK_ITEMS.CURRENCY'"
        [options]="currencies"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)">
      </raily-input>
    </ng-container>

  </ng-container>

  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>

</div>
