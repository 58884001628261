export function isoToDate(isoDate: string) {
  return new Intl.DateTimeFormat('en-GB').format(new Date(isoDate));
}

export function dateToIso(dateString: string): string {
  const parts = dateString.split('/');
  let date: Date;

  if (parts.length === 1) {
    date = new Date(Date.UTC(Number(parts[0]), 0, 1));
  } else if (parts.length === 2) {
    date = new Date(Date.UTC(Number(parts[1]), Number(parts[0]) - 1, 1));
  } else if (parts.length === 3) {
    date = new Date(Date.UTC(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0])));
  } else {
    throw new Error('Invalid date format');
  }

  return date.toISOString();
}

export function formatDate(isoDate: string, attributeDateFormat: string): string {
  const date = new Date(isoDate);
  let formatOptions: any = {};

  if (attributeDateFormat) {
    switch (attributeDateFormat.toLowerCase()) {
      case 'mm/dd/yyyy':
        formatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
        break;
      case 'yyyy':
        formatOptions = { year: 'numeric' };
        break;
      case 'mm/yyyy':
        formatOptions = { month: '2-digit', year: 'numeric' };
        break;
      default:
        formatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        break;
    }
  } else {
    formatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  }

  return new Intl.DateTimeFormat('en-GB', formatOptions).format(date);
}
