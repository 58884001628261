import { Injectable } from '@angular/core';
import { HttpService } from '@raily/shared';
import { Observable } from 'rxjs';
import { Permission } from '../model';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private http: HttpService
  ) {}

  getPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>('organisations/my/features/permissions');
  }
}
