<div>
  <div class="flex flex-row" style="transform: translateY(4px)">
    <label [for]="label | transloco" class="font-medium text-900">
      {{ label | transloco }}
      <span *ngIf="required">*</span>
    </label>
  </div>

  <form [formGroup]="form" class="flex flex-row gap-2">
    <div class="w-3">
      <raily-search-autocomplete
        #input
        [label]="'Code'"
        [url]="'companies/search'"
        [apiSearch]="true"
        [labelField]="'ricsCode'"
        [showRequiredAsterisks]="false"
        formControlName="rics">
      </raily-search-autocomplete>
    </div>

    <div class="w-9">
      <raily-search-autocomplete
        #input
        [label]="'Name'"
        [url]="'companies/search'"
        [apiSearch]="true"
        [labelField]="'shortName'"
        [showRequiredAsterisks]="false"
        formControlName="name">
      </raily-search-autocomplete>
    </div>
  </form>
</div>
