export interface AppFeatures {
  OrganizationAdministration: string;
  AssetManagement: string;
  DamageReports: string;
  Core: string;
}

export const AppFeaturesValues: AppFeatures = {
  OrganizationAdministration: 'F_UI_OrganisationAdministration',
  AssetManagement: 'F_UI_AssetManagement',
  DamageReports: 'F_UI_DamageReports',
  Core: 'F_UI_Core'
};
