import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT } from '../../di';
import { Environment } from '../../model';
import { LoadingStateService } from '../loading-state/loading-state.service';
import { catchError, EMPTY, finalize, Observable, of, switchMap, tap } from 'rxjs';
import { ErrorService } from '../error/error.service';
import { LanguageService } from '../language/language.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
    private loadingStateService: LoadingStateService,
    private errorService: ErrorService,
    private languageService: LanguageService,
    @Inject(ENVIRONMENT) private env: Environment
  ) { }

  get<T>(url: string, params?: { [param: string]: any }, options: any = {}): Observable<T> {
    return of(null).pipe(
      tap(() => this.loadingStateService.setLoadingState(true)),
      switchMap(() => this.http.get<T>(`${this.env.apiUrl}/${url}`, { params, ...options, headers: { ...this.getHeaders(), ...options.headers } })),
      catchError(error => {
        this.errorService.httpError(error);
        return EMPTY;
      }),
      finalize(() => this.loadingStateService.setLoadingState(false))
    ) as Observable<T>;
  }

  post<T, R = T>(url: string, body: R, options: any = { headers: {} }): Observable<T> {
    this.loadingStateService.setLoadingState(true);
    return this.http.post<T>(`${this.env.apiUrl}/${url}`, body, { observe: 'body', responseType: 'json', ...options, headers: { 'ContentType': 'application/json', ...this.getHeaders(), ...options.headers } }).pipe(
      catchError(error => {
        this.errorService.httpError(error);
        return EMPTY;
      }),
      finalize(() => this.loadingStateService.setLoadingState(false))
    ) as Observable<T>;
  }

  patch<T, R = T>(url: string, body: R, options: any = { headers: {} }): Observable<T> {
    this.loadingStateService.setLoadingState(true);
    return this.http.patch<T>(`${this.env.apiUrl}/${url}`, body, { observe: 'body', responseType: 'json', ...options, headers: { 'ContentType': 'application/json', ...this.getHeaders(), ...options.headers } }).pipe(
      catchError(error => {
        this.errorService.httpError(error);
        return EMPTY;
      }),
      finalize(() => this.loadingStateService.setLoadingState(false))
    ) as Observable<T>;
  }

  delete<T>(url: string, options: any = { headers: {} }): Observable<T> {
    this.loadingStateService.setLoadingState(true);
    return this.http.delete<T>(`${this.env.apiUrl}/${url}`, { ...options, headers: { ...this.getHeaders(), ...options.headers } }).pipe(
      catchError(error => {
        this.errorService.httpError(error);
        return EMPTY;
      }),
      finalize(() => this.loadingStateService.setLoadingState(false))
    ) as Observable<T>;
  }

  getUrl(path: string) {
    return `${this.env.apiUrl}/${path}`;
  }

  private getHeaders() {
    return { 'Accept-Language': this.languageService.currentLanguage };
  }
}
