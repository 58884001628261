export interface FileInfo {
  id: string;
  resourceId: string;
  name: string;
  extension: string;
  path: string;
  blobPath: string;
  url: string;
  uploadDate: string;
  uploadedBy: string
}
