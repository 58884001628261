import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import { FileInfo } from '../../model';
import { FileUploadEvent } from 'primeng/fileupload';

@Injectable({
  providedIn: 'root'
})
export class SimpleFileService {

  constructor(private httpService: HttpService) { }

  getFileInfo(resourceId: string, fileId: string): Observable<FileInfo> {
    return this.httpService.get(`files/resources/${resourceId}/files/${fileId}/signedurl`);
  }
}

export abstract class FileService extends SimpleFileService {
  abstract onFileUpload(event: FileUploadEvent): void;
  abstract getUploadUrl(resourceId?: string): string;
}
