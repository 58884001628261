import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@raily/shared';
import { Router } from '@angular/router';

@Component({
  selector: 'raily-homepage',
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss',
})
export class HomepageComponent implements OnInit {

  constructor(
    public layoutService: LayoutService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!this.layoutService.isMobile) {
      this.router.navigate(['/assets', 'dashboard', 'Wagon']);
    }
  }
}
