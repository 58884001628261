<div class="sidebar-container surface-section border-right-1 surface-border flex flex-column w-18rem select-none fixed left-0 top-0">

  <div class="p-3 flex align-items-center justify-content-center">
    <img src="assets/logo.svg" alt="Image" height="40">
  </div>

  <div class="sidebar-content overflow-y-auto">
    <ul class="list-none p-2 m-0">

      <li *ngFor="let group of (visibleNavigation$ | async)">

        <a
          pRipple
          class="flex align-items-center cursor-pointer p-3 text-800 transition-colors transition-duration-150"
          pStyleClass="@next"
          enterClass="hidden"
          enterActiveClass="slidedown"
          leaveToClass="hidden"
          leaveActiveClass="slideup">

          <ng-container *ngTemplateOutlet="iconView; context: { icon: group.icon }"></ng-container>

          <span class="font-large">
            {{ (group.i18nKey || group.name) | transloco }}
          </span>

          <div class="ml-auto">
            <i class="pi pi-chevron-down ml-2"></i>
          </div>

        </a>

        <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
          <li *ngFor="let item of group.items">
            <a
              pRipple
              class="flex align-items-center cursor-pointer p-3 text-500 transition-colors transition-duration-150 border-left-2 border-transparent"
              *ngxPermissionsOnly="item.permissions"
              [routerLinkActive]="['border-primary']"
              [routerLink]="item.link"
              [routerLinkActiveOptions]="{ exact: item.exactMatch!! }"
              (click)="layoutService.toggleSidebar(false)">

              <ng-container *ngTemplateOutlet="iconView; context: { icon: item.icon }"></ng-container>

              <span class="font-medium">
                {{ (item.i18nKey || item.name) | transloco }}
              </span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="surface-section p-2 mt-auto border-top-1 border-right-1 surface-border fixed bottom-0 left-0 w-18rem">
    <ul class="list-none p-2 m-0 hidden origin-bottom animation-duration-150">
<!--      <li>-->
<!--        <a pRipple class="flex align-items-center cursor-pointer p-3 text-700 border-round transition-colors transition-duration-150">-->
<!--          <i class="pi pi-user mr-2"></i>-->
<!--          <span class="font-medium">Profile</span>-->
<!--        </a>-->
<!--      </li>-->
      <li>
        <a routerLink="settings" pRipple class="flex align-items-center cursor-pointer p-3 text-700 border-round transition-colors transition-duration-150">
          <i class="pi pi-cog mr-2"></i>
          <span class="font-medium">{{ 'FEATURE.MENU.SETTINGS' | transloco }}</span>
        </a>
      </li>
      <li>
        <a pRipple class="flex align-items-center cursor-pointer p-3 text-700 border-round transition-colors transition-duration-150">
          <i class="pi pi-sign-out mr-2"></i>
          <span class="font-medium" (click)="onLogout()">{{ 'FEATURE.MENU.SIGN_OUT' | transloco }}</span>
        </a>
      </li>
    </ul>
    <a pRipple class="p-3 flex align-items-center cursor-pointer text-700 border-round transition-colors transition-duration-150"
       pStyleClass="@prev" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout">
      <img src="assets/avatar-f-1.png" class="mr-2" style="width: 28px; height: 28px"/>
      <span *ngIf="(authService.user$ | async) as user" class="font-medium">{{ user?.name }} {{ user?.surname }}</span>
      <i class="pi pi-chevron-up ml-auto"></i>
    </a>
  </div>
</div>

<ng-template #iconView let-icon="icon">
  <img
    *ngIf="icon && !isPrimeIcon(icon)"
    class="item-icon mr-2"
    [src]="icon"
    width="24px"
    height="24px"/>

  <i *ngIf="icon && isPrimeIcon(icon)" [class]="icon + ' navigation-prime-icon'"></i>
</ng-template>
