import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services'
import { map, take, tap } from 'rxjs';

export const guestGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.loggedIn$.pipe(
    take(1),
    tap(loggedIn => {
      if (loggedIn) {
        router.navigate(['/'])
      }
    }),
    map(loggedId => !loggedId),
  )
}
