export function encodeBoolOptions(options: number[]) {
  return options.reduce((acc, curr) => acc | curr, 0);
}

export function decodeBoolOptions(value: number) {
  const bitString = value?.toString(2) ?? '';
  const reversed = [...bitString].reverse().join('');
  const values: number[] = [];
  for (let i = 0; i < reversed.length; i++) {
    const bitValue = Math.pow(2, i);
    if (reversed[i] === '1') {
      values.push(bitValue);
    }
  }
  return values;
}
