import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { filter, map, Observable, ReplaySubject, shareReplay, take, tap } from 'rxjs';
import { SelectOptions, TranslatedProperty } from '../../model';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  currentLanguage?: string;
  languageOptions: { id: string, label: string }[] = [];
  translationsLoaded$!: Observable<boolean>;
  private translationsLoadedReplaySubject = new ReplaySubject(1);

  static enums = new Map<object, { [p: string | number]: string }>();

  constructor(private translocoService: TranslocoService) {
    this.currentLanguage = this.translocoService.getActiveLang();
    setTimeout(() => this.setupLanguageOptions(), 100);

    this.translocoService.events$.pipe(
      filter(event => event.type === 'translationLoadSuccess'),
    ).subscribe(this.translationsLoadedReplaySubject);

    this.translationsLoaded$ = this.translationsLoadedReplaySubject.pipe(
      map(() => true)
    );
  }

  changeLanguage(lang: string) {
    this.translocoService.setActiveLang(lang);
    localStorage.setItem('lang', lang);
    setTimeout(() => this.setupLanguageOptions(), 100);
  }

  setupLanguageOptions() {
    this.translationsLoaded$.pipe(take(1)).subscribe(() => {
      this.languageOptions = [];
      for (const lang of this.translocoService.getAvailableLangs() as string[]) {
        this.languageOptions.push({
          id: lang,
          label: this.translocoService.translate(`FEATURE.SETTINGS.LANGS.${lang}`)
        })
      }
      this.currentLanguage = this.translocoService.getActiveLang();
    });
  }

  translate(key: string) {
    return this.translocoService.translate(key);
  }

  static registerEnum(enumObject: object, translations: { [p: string | number]: string }) {
    LanguageService.enums.set(enumObject, translations);
  }

  translateEnumValue(enumObject: object, value: any): Observable<string> {
    return this.getEnumTranslations(enumObject).pipe(
      map(translations => this.translocoService.translate(translations[value]))
    );
  }

  getEnumOptions(enumObject: object): Observable<SelectOptions> {
    console.log(enumObject);
    return this.getEnumTranslations(enumObject).pipe(
      map(translations => Object.keys(translations).map(key =>
        ({ value: key, label: this.translocoService.translate(translations[key]) })
      )),
    );
  }

  getEnumTranslations(enumObject: object): Observable<{ [p: string | number]: string }> {
    return this.translationsLoaded$.pipe(
      take(1),
      map(() => {
        const translations = LanguageService.enums.get(enumObject);
        if (!translations) {
          console.error(`No tranlations for ${JSON.stringify(enumObject)}`);
          return {};
        }
        return translations;
      })
    );
  }

  getTranslatedPropertyValue(property: TranslatedProperty) {
    const translation = property.translations.find(t => t.code === this.currentLanguage);
    return translation?.value ?? property.translations[0].value;
  }

  static getSavedLanguage() {
    return localStorage.getItem('lang') ?? 'en';
  }
}
