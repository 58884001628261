import { Component, computed, EventEmitter, input, Input, Output } from '@angular/core';
import { Column, ColumnType } from '../../../model';
import { Router } from '@angular/router';
import { LayoutService } from '../../../services';

@Component({
  selector: 'raily-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss'],
})
export class ListPageComponent {
  columns = input<Column[]>();
  transformedColumns = computed(() =>
    this.columns()?.map(column => {
      if (column.goToDetails) {
        column.callback = this.navigate.bind(this)
      }
      return column;
    })
  )

  @Input() title!: string;
  @Input() items!: any[];
  @Input() routerPath!: string;
  @Input() filterFields = ['id'];
  @Input() hideButtons?: string[];
  @Input() hideCreateButton = false;
  @Input() showButtonsColumn = false;
  @Output() delete = new EventEmitter<string>();
  @Input() overridenDetailsRouterPath?: string;

  constructor(
    private router: Router,
    public layoutService: LayoutService
  ) {}

  navigate(item: any, index: number, column: Column) {
    this.goToDetails(item);
  }

  goToDetails(item: any) {
    if (this.overridenDetailsRouterPath) {
      this.router.navigate([this.overridenDetailsRouterPath, item.id]);
    } else{
      this.router.navigate([this.routerPath, 'details', item.id]);
    }
  }

  goToEdit(item: any) {
    this.router.navigate([this.routerPath, 'edit', item.id])
  }

  onDelete(item: any) {
    this.delete.emit(item.id);
  }
}
