<raily-page-layout [title]="title">

  <ng-container actions>
    <ng-container *ngTemplateOutlet="actions"></ng-container>
  </ng-container>

  <ng-container content>
    <raily-table
      [items]="items"
      [columns]="transformedColumns()!"
      [filterFields]="filterFields"
      [scrollHeight]="layoutService.isMobile ? 'calc(100vh - 280px)' : 'calc(100vh - 240px)'"
      [showButtonsColumn]="showButtonsColumn"
    >
      <ng-template let-item>
        <raily-list-action-buttons
          [hide]="hideButtons"
          (details)="goToDetails(item)"
          (edit)="goToEdit(item)"
          (delete)="onDelete(item)">
        </raily-list-action-buttons>
      </ng-template>
    </raily-table>
  </ng-container>

</raily-page-layout>

<ng-template railyActionBar #actions>
  <p-button
    icon="pi pi-file"
    *ngIf="!hideCreateButton"
    [label]="'BUTTON.CREATE' | transloco"
    routerLink="create">
  </p-button>
</ng-template>
