<raily-center-card>
  <div *ngIf="audit" class="raily-form">
    <raily-input
      [label]="'FEATURE.AUDIT.CREATED_BY'"
      [disabled]="true"
      [ngModel]="audit.createdBy">
    </raily-input>

    <raily-input
      [label]="'FEATURE.AUDIT.CREATED'"
      [disabled]="true"
      [ngModel]="audit.creationTime | date: 'dd/MM/yyyy HH:mm'">
    </raily-input>

    <raily-input
      [label]="'FEATURE.AUDIT.LAST_MODIFIED_BY'"
      [disabled]="true"
      [ngModel]="audit.lastModifiedBy">
    </raily-input>

    <raily-input
      [label]="'FEATURE.AUDIT.LAST_MODIFIED'"
      [disabled]="true"
      [ngModel]="audit.lastModificationTime | date:'dd/MM/yyyy HH:mm'">
    </raily-input>
  </div>
</raily-center-card>
