import { AbstractControl } from '@angular/forms';

export const getValidationClasses = (control: AbstractControl) => {
  const invalid = control.invalid && (control.dirty || control.touched);
  const touched = control.touched;
  const dirty = control.dirty;
  return {
    'ng-invalid': invalid,
    'p-invalid': invalid,
    'ng-touched': touched,
    'p-touched': touched,
    'ng-dirty': dirty,
    'p-dirty': dirty,
  }
}
