export const mockDamageReport = {
  "id": "4a78b184-a50d-4b00-966e-8c034c250541",
  "railUndertakerUser": "GATX Rail Austria GmbH",
  "reportNumber": "3221",
  "consignmentNumber": "22312",
  "trainNumber": "iC3000",
  "wagonNumber": "47 92 1538 624-7",
  "detectedOn": "2024-07-30T22:00:00+00:00",
  "forwardingStation": "Wrocław - PL",
  "dateOfDispatch": "2024-07-30T22:00:00+00:00",
  "destinationStation": "Warszawa - PL",
  "wagonState": "LOADED",
  "keeper": "GATX Rail Germany GmbH",
  "damages": [
    {
      "code": "2.5.4.1",
      "status": "NEW",
      "description": "Zawieszenie - Usprężynowanie wózka Y25 i pochodnych - Kołpak(i) sprężyny dotyka(ją) ramy wózka - Jeden kołpak sprężyny na wózek dotyka ramy"
    }
  ],
  "remarks": null,
  "existingLabels": "K",
  "existingLabelsDate": "2024-07-30T22:00:00+00:00",
  "existingLabelsRU": "GATX Rail France SAS",
  "newLabels": "K",
  "wagonDetached": false,
  "dispatchToWorkshop": null,
  "details": "WEAR_AND_TEAR",
  "thirdPartyDetails": null,
  "damageDetectedUponAcceptance": "GCU_RU",
  "damageDetectedUponAcceptanceFromCompany": "GAIAOSE SA",
  "damageDetectedUponAcceptanceFromCompanyRics": null,
  "attachments": [],
  "railUndertakerUserRics": "4006",
  "existingLabelsRURics": "4247",
  "keeperRics": "4139",
  "date": "2024-07-30T22:00:00+00:00",
  "place": "Wrocław",
  "contactInformation": "Adam Testowy +48  2137 0700"
};
