import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { delay } from 'rxjs';

@Component({
  selector: 'raily-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  LoginViewState = LoginViewState;
  viewState: LoginViewState = LoginViewState.Login;
  resetPasswordToken?: string;
  resetPasswordLogin?: string;

  username = '';
  password = '';
  repeatPassword = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams['token']) {
      this.viewState = LoginViewState.ResetPassword;
      this.resetPasswordToken = this.activatedRoute.snapshot.queryParams['token'];
      this.resetPasswordLogin = this.activatedRoute.snapshot.queryParams['login'];
    }
  }

  login() {
    this.authService.login(this.username, this.password).pipe(delay(1)).subscribe(() => this.router.navigate(['/']));
  }

  forgotPassword() {
    this.viewState = LoginViewState.ForgotPassword;
  }

  forgotPasswordRequest() {
    this.authService.forgotPassword(this.username).subscribe(() => {
      this.viewState = LoginViewState.ForgotPasswordSuccess;
    })
  }

  resetPassword() {
    this.authService.resetPassword(this.resetPasswordToken!, this.resetPasswordLogin!, this.password).subscribe(() => {
      this.viewState = LoginViewState.Login;
      this.password = '';
      this.repeatPassword = '';
    });
  }

  back() {
    this.viewState = LoginViewState.Login;
  }
}

enum LoginViewState {
  Login,
  ForgotPassword,
  ForgotPasswordSuccess,
  ResetPassword,
}
