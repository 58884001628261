<raily-page-header [title]="title">
  <ng-container *ngIf="!layoutService.isMobile">
    <p-button
      *ngIf="backButton !== 'none'"
      icon="pi pi-arrow-left"
      [label]="'BUTTON.BACK' | transloco"
      (click)="back()">
    </p-button>
    <ng-content select="[actions]"></ng-content>
  </ng-container>
</raily-page-header>
<div class="content surface-section h-full px-3 md:px-4 lg:px-6" [class]="layoutService.isMobile ? 'pb-6' : 'pb-0'">
  <ng-content select="[content]"></ng-content>
</div>
