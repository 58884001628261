import { Component, input } from '@angular/core';
import { LayoutService } from '@raily/shared';

@Component({
  selector: 'raily-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrl: './image-carousel.component.scss',
})
export class ImageCarouselComponent {
  images = input<string[]>([]);

  constructor(public layoutService: LayoutService) {}
}
