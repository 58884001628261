<div class="flex flex-row justify-content-end gap-2">
  <p-button
    *ngIf="detailsComponent.pageLayoutComponent.backButton !== 'none'"
    icon="pi pi-arrow-left"
    [label]="'BUTTON.BACK' | transloco"
    (click)="detailsComponent.pageLayoutComponent.back()">
  </p-button>

  <ng-container *ngIf="['edit', 'create'].includes((detailsComponent.detailsService.mode$ | async)!)">

    <p-button
      icon="pi pi-times"
      [label]="'BUTTON.CANCEL' | transloco"
      (click)="detailsComponent.cancel()">
    </p-button>

    <p-button
      icon="pi pi-check"
      [disabled]="!detailsComponent.validationService.isValid"
      [label]="'BUTTON.SAVE' | transloco"
      (click)="detailsComponent.save()">
    </p-button>
  </ng-container>

  <ng-container *ngIf="['details'].includes((detailsComponent.detailsService.mode$ | async)!)">
    <p-button
      *ngIf="detailsComponent.showEditButton"
      icon="pi pi-file-edit"
      [label]="'BUTTON.EDIT' | transloco"
      (click)="detailsComponent.edit()">
    </p-button>
  </ng-container>
</div>


