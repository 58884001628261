import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { RailyError } from '../../model';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private errorSubject$ = new Subject<RailyError>();
  errors$ = this.errorSubject$.asObservable();

  httpError(error: HttpErrorResponse) {

    let railyError = RailyError.app();

    if (error.error instanceof ErrorEvent) {
      railyError = RailyError.app(error.error);
    } else {
      switch (error.status) {
        case 401:
          railyError = RailyError.unauthorized(error.url, error.error);
          break;
        case 403:
          railyError = RailyError.forbidden(error.url, error.error);
          break;
        case 404:
          railyError = RailyError.notFound(error.url, error.error);
          break;
      }
    }

    return this.handleError(railyError);
  }

  handleError(error: RailyError): Observable<RailyError> {
    console.error(error.toString());
    this.errorSubject$.next(error);
    return of(error);
  }
}
