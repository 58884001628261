import { PrimeNGConfig } from 'primeng/api';
import { APP_INITIALIZER } from '@angular/core';

export const initializePrimeNGFactory = (primeConfig: PrimeNGConfig) => () => {
  primeConfig.ripple = false;
};

export const primeNGConfigProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializePrimeNGFactory,
  deps: [PrimeNGConfig],
  multi: true,
};
