<div *ngIf="formControl" class="flex flex-column">

  <div *ngIf="label" class="flex flex-row mb-2">
    <label [for]="label | transloco" class="font-medium text-900">
      {{ label | transloco }}
      <span *ngIf="required && showRequiredAsterisks">*</span>
    </label>
  </div>

  <p-autoComplete
    [id]="label | transloco"
    [(ngModel)]="value"
    [disabled]="isDisabled"
    [dropdown]="dropdown"
    [delay]="100"
    [suggestions]="(options$ | async) || []"
    [required]="required"
    [ngClass]="validationClasses"
    (ngModelChange)="onValueChange($event)"
    (completeMethod)="onFilterChange($event)"
    [optionLabel]="labelField"
    [styleClass]="'w-12'"
    [inputStyleClass]="'w-12'"
    [optionValue]="optionValue"
    (onBlur)="blur.emit()"
    appendTo="body">
  </p-autoComplete>

</div>
