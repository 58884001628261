export interface Column {
  id: string;
  titleI18n: string;
  propertyPath: string;
  type?: ColumnType;
  width?: string;
  transform?: (rowData: any) => string;
  goToDetails?: boolean;
  callback?: (item: any, index: number, column: Column) => void | Promise<void>;
  dateFormat?: string;
  sortable?: boolean;
  additionalData?: any;
  isTranslated?: boolean;
  translationPath?: string;
  editCallback?: (item: any, column: Column) => void | Promise<void>;
  editDisabled?: boolean;
  selectOptions?: { value: string; label: string }[];
  styleClasses?: string[];
}

export enum ColumnType {
  Text = 'text',
  Boolean = 'boolean',
  Date = 'date',
  Select = 'select'
}
