import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '@raily/shared';
import { AuthRoutingModule } from './auth-routing.module';
import { APP_FEATURES, APP_PERMISSIONS } from './di';
import { AppPermissionsValues, AppFeaturesValues } from './model';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from './services';

@NgModule({
  imports: [CommonModule, AuthRoutingModule, SharedModule],
  declarations: [LoginComponent]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        {
          provide: APP_FEATURES,
          useValue: AppFeaturesValues,
        },
        {
          provide: APP_PERMISSIONS,
          useValue: AppPermissionsValues,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
      ],
    };
  }
}
