import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { LoadingStateService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[railyDisableWhileLoading]',
})
export class DisableWhileLoadingDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private loadingStateService: LoadingStateService
  ) {}

  ngOnInit(): void {
    this.loadingStateService.loading$.pipe(
      untilDestroyed(this)
    ).subscribe(isLoading => this.setDisabled(isLoading));
  }

  setDisabled(disabled: boolean) {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', disabled);
  }
}
