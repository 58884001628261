import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import {
  ControlValueAccessor, FormBuilder,
  FormControl, FormGroup,
  NgControl,
  Validators
} from '@angular/forms';
import { Company } from '../../../model';

@Component({
  selector: 'raily-company-input',
  templateUrl: './company-input.component.html',
  styleUrl: './company-input.component.scss'
})
export class CompanyInputComponent implements ControlValueAccessor, AfterViewInit, OnInit  {
  @Input() required = false;
  @Input() label!: string;
  isDisabled = false;
  value?: Company;
  onChange = (_: string) => {};
  onTouch = () => {};

  formControl: FormControl;
  form!: FormGroup;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    ngControl.valueAccessor = this;
    this.formControl = ngControl.control as FormControl;
  }

  ngOnInit(): void {
    const validators = this.required ? [Validators.required] : [];
    this.form = this.fb.group({
      name: this.fb.control(this.value, validators),
      rics: this.fb.control(this.value, validators),
    });
    this.form.get('name')!.valueChanges.subscribe((value) => {
      if (typeof value === 'string') {
        return;
      }
      this.form.get('rics')!.setValue(value, { emitEvent: false });
      this.onChange(value);
    });
    this.form.get('rics')!.valueChanges.subscribe((value) => {
      if (typeof value === 'string') {
        return;
      }
      this.form.get('name')!.setValue(value, { emitEvent: false });
      this.onChange(value);
    });
  }

  ngAfterViewInit(): void {
    this.formControl = this.ngControl?.control as FormControl;
    if (this.required) {
      this.formControl.addValidators(Validators.required);
    }
    this.cdr.detectChanges();
  }

  writeValue(value: Company): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
