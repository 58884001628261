import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self
} from '@angular/core';
import {
  ControlValueAccessor, FormControl, NgControl, Validators
} from '@angular/forms';
import { BehaviorSubject, delay, map, Observable, Subject, switchMap } from 'rxjs';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { ApiSearchAutocompleteService } from '../../../services/api/api-search-autocomplete.service';
import { getValidationClasses } from '../../../util/validation';

@Component({
  selector: 'raily-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrl: './search-autocomplete.component.scss',
})
export class SearchAutocompleteComponent implements ControlValueAccessor, AfterViewInit {
  @Input() label?: string;
  @Input() required = false;
  @Input() url!: string;
  @Input() apiSearch = false;
  @Input() labelField!: string;
  @Input() searchFields?: string[];
  @Input() dropdown = false;
  @Input() withLocation = false;
  @Input() showRequiredAsterisks = true;
  @Input() additionalData?: any;
  @Input() filterFunction?: (options: any[]) => any[];
  @Input() optionValue?: string;
  @Output() blur = new EventEmitter();
  @Output() onChangeEvent = new EventEmitter<any>();

  value?: any;
  isDisabled = false;

  filterOptions = new Subject<AutoCompleteCompleteEvent>();

  options$: Observable<any[]> = this.filterOptions.pipe(
    switchMap((event) => this.apiSearch ?
      this.apiSearchAutocompleteService.searchApi(event?.query?.toLowerCase(), this.url, this.withLocation, this.additionalData) :
      this.apiSearchAutocompleteService.searchLocal(event?.query?.toLowerCase(), this.url, this.searchFields)
    ),
    map(options => this.filterFunction ? this.filterFunction(options) : options)
  ) as Observable<any[]>;

  formControl!: FormControl;

  get validationClasses() {
    return getValidationClasses(this.formControl);
  }

  onChange = (_: any) => {};
  onTouch = () => {};

  private valueChangesSubject = new BehaviorSubject(this.value);
  valueChanges: Observable<any> = this.valueChangesSubject.pipe(
    delay(1)
  );

  constructor(
    private apiSearchAutocompleteService: ApiSearchAutocompleteService,
    @Optional() @Self() public ngControl: NgControl,
    private cdr: ChangeDetectorRef
  ) {
    ngControl.valueAccessor = this;
  }

  ngAfterViewInit(): void {
    this.formControl = this.ngControl?.control as FormControl;
    if (this.required) {
      this.formControl.addValidators(Validators.required);
    }
    this.cdr.detectChanges();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  onValueChange(value: any) {
    this.onChange(value);
    this.onChangeEvent.emit(value);
    this.valueChangesSubject.next(value);
  }

  onFilterChange(event: AutoCompleteCompleteEvent) {
    this.onChange(event.query);
    this.filterOptions.next(event);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
