import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { LocationService } from '../location/location.service';

@Injectable({
  providedIn: 'root'
})
export class ApiSearchAutocompleteService {

  cache: Record<string, any[]> = {};

  constructor(
    private httpService: HttpService,
    private locationService: LocationService
  ) {}

  searchLocal(query: string, url: string, fields = ['name', 'tag']): Observable<any[]> {
    if (this.cache[url]) {
      return of(this.findInCache(query, url, fields));
    }
    return this.httpService.get(url).pipe(
      tap(result => this.cache[url] = result as any[]),
      map(() => this.findInCache(query, url, fields))
    );
  }

  searchApi(query: string, url: string, withLocation?: boolean, additionalData?: any): Observable<any[]> {
    let urlWithParams = url;
    if (query) {
      urlWithParams = `${urlWithParams}?query=${query}`;
    }
    if (withLocation && this.locationService.getLocation()) {
      const { lat, long } = this.locationService.getLocation();
      urlWithParams = `${urlWithParams}&latitude=${lat}&longitude=${long}`;
    }
    if (additionalData) {
      urlWithParams = `${urlWithParams}&${new URLSearchParams(additionalData).toString()}`;
    }
    return this.httpService.get<any[]>(urlWithParams);
  }

  private findInCache(query: string, url: string, fields: string[]) {
    return this.cache[url].filter(option => fields.some(field => option[field]?.toLowerCase().includes(query.toLowerCase())));
  }
}
