<div class="flex flex-column justify-content-center p-4 md:p-6">
  <ng-container *ngIf="layoutService.isMobile; else desktopLayout">
    <img class="logo-mobile" src="assets/logo.svg"/>
    <div class="flex flex-column justify-content-center align-items-center gap-3">
      <p-button [label]="'FEATURE.HOME.CREATE_DAMAGE_REPORT' | transloco" [routerLink]="'/damage-reports/create'"></p-button>
      <p-button [label]="'FEATURE.HOME.CREATE_TRAIN_COMPOSITION' | transloco" [routerLink]="'/train-composition/create'"></p-button>
    </div>
  </ng-container>

</div>

<ng-template #desktopLayout>
  <img class="logo" src="assets/logo.svg"/>
</ng-template>
