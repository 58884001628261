import { Component, Inject } from '@angular/core';
import { NavigationGroup,  } from '../../model';
import { AuthService } from '@raily/auth';
import { LayoutService, NAVIGATION } from '@raily/shared';
import { map, shareReplay } from 'rxjs';

@Component({
  selector: 'raily-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  visibleNavigation$ = this.layoutService.isMobile$.pipe(
    shareReplay(1),
    map(isMobile => {
      if (isMobile) {
        return this.navigation.filter(nav => nav.mobile).map(nav => {
          nav.items = nav.items.filter(item => item.mobile);
          return nav;
        });
      } else {
        return this.navigation;
      }
    })
  );

  constructor(
    public authService: AuthService,
    public layoutService: LayoutService,
    @Inject(NAVIGATION) public navigation: NavigationGroup[],
  ) {}

  onLogout() {
    this.authService.logout();
  }

  isPrimeIcon(icon: string) {
    return icon && icon.includes('pi ');
  }
}
