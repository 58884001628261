import { Component, Input } from '@angular/core';
import { DetailsPageComponent } from '../details-page/details-page.component';

@Component({
  selector: 'raily-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrl: './action-buttons.component.scss',
})
export class ActionButtonsComponent {
  @Input() detailsComponent!: DetailsPageComponent;
}
