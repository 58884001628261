import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DamageReportLinkService {

  damageReport: DamageReportData = {
    inline: false,
    callbackUrl: null,
    data: {},
  }

  constructor(private router: Router) {}

  damageReportForTrainComposition(trainComposition: any, callbackUrl: string, wagonAssetId: string, edit: boolean, damageReportId?: string) {
    if (edit) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/damage-reports', 'details', damageReportId])
      );
      window.open(url, '_blank');
    } else {
      this.damageReport.inline = true;
      this.damageReport.callbackUrl = callbackUrl;
      this.damageReport.data.wagonAssetId = wagonAssetId;
      this.damageReport.data.trainComposition = trainComposition;
      this.router.navigate(['/damage-reports', 'create']);
    }
  }

  setDamageReportId(damageReportId: string) {
    this.damageReport.data.damageReportId = damageReportId;
  }

  backToTrainComposition() {
    this.router.navigateByUrl(this.damageReport.callbackUrl!);
  }

  damageReportForTrainCompositionComplete() {
    this.damageReport.inline = false;
    this.damageReport.callbackUrl = null;
    this.damageReport.data = {};
  }

  getAsset(): any {
    const assets = [...this.damageReport.data.trainComposition.wagons, ...this.damageReport.data.trainComposition.locomotives];
    return assets.find(asset => asset.id === this.damageReport.data.wagonAssetId)!;
  }
}

interface DamageReportData {
  inline: boolean;
  callbackUrl: string | null;
  data: {
    wagonAssetId?: string;
    trainComposition?: any;
    damageReportId?: string;
  }
}
