<div class="app-container overflow-hidden">

  <ng-container *ngIf="authService.loggedIn$ | async else guest">

    <ng-container *ngIf="layoutService.isMobile$ | async else desktopLayout">
      <div class="content surface-ground">
        <router-outlet></router-outlet>
        <div class="raily-action-bar-mobile fixed bottom-0 left-0 right-0 z-3 flex flex-row align-items-center">
          <p-button
            icon="pi pi-bars"
            class="burger"
            [text]="true"
            (click)="layoutService.toggleSidebar()">
          </p-button>
          <div class="actions w-full flex justify-content-end">
            <ng-container #actionBar></ng-container>
          </div>
        </div>
      </div>
      <p-sidebar [(visible)]="layoutService.sidebarOpen">
        <ng-template pTemplate="headless">
          <ng-container *ngTemplateOutlet="sidebar"></ng-container>
        </ng-template>
      </p-sidebar>
    </ng-container>

    <ng-template #desktopLayout>
      <ng-container *ngTemplateOutlet="sidebar"></ng-container>
      <div class="content desktop-content surface-ground">
        <router-outlet></router-outlet>
      </div>
    </ng-template>

  </ng-container>

  <ng-template #guest>
    <router-outlet></router-outlet>
  </ng-template>

  <ng-template #sidebar>
    <raily-sidebar></raily-sidebar>
  </ng-template>

  <p-progressSpinner
    *ngIf="loadingStateService.loading$ | async"
    class="progress-spinner"
    styleClass="w-3rem h-3rem"
    strokeWidth="3">
  </p-progressSpinner>

  <p-toast [position]="layoutService.isMobile ? 'bottom-center' : 'bottom-right'"></p-toast>

</div>


