import { AfterViewInit, Directive, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ValidationService } from '../../../services';

@Directive()
export class FormComponent implements AfterViewInit {
  @ViewChild('form') form!: NgForm;
  protected formPartialId?: string;

  constructor(public validationService: ValidationService) {}

  ngAfterViewInit(): void {
    this.form.statusChanges?.subscribe(() => {
      this.validationService.updateValidation(!this.form.invalid, this.formPartialId);
    });
  }
}
