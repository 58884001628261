export interface NavigationGroup {
  name: string;
  i18nKey?: string;
  icon?: string;
  items: NavigationItem[];
  mobile?: boolean;
  order?: number;
}

export interface NavigationItem {
  name: string;
  i18nKey?: string;
  icon?: string;
  permissions: string[];
  link: string;
  mobile?: boolean;
  exactMatch?: boolean;
}
